/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import ReactDOM from 'react-dom'
// // You can delete this file if you're not using it

// use Hydrate funcion
// const loadableReady = require('@loadable/component').loadableReady;

// exports.replaceHydrateFunction = () => {
//     return (element, container, callback) => {
//         loadableReady(() => {
//             ReactDOM.render(element, container, callback);
//         });
//     };
// };

const { setCreditId } = require('./src/helpers/sessionStorage')


exports.onPreRouteUpdate =  ({ location, prevLocation }) => {
    setCreditId()
}